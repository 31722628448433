:root {
  --color-main: #5c2018;
  --color-first: #282c34;
  --color-second: #bc4639;
  --color-third: #ffaf71;
  --color-fourth: #007e7c;
  --color-fifth: #edccac;
  --color-sixth: #000;
  --color-seventh: #84fa84;
  --color-text: #fff;
  --color-link: #a52a2a;
}

.App {
  --width: 400px;
  text-align: center;
  background-color: var(--color-main);
  min-height: 100vh;
  width: 100%;
  padding-top: 100px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-first);
  font-size: calc(var(--width) / 8);
  color: var(--color-first);
  text-shadow: 4px 4px 10px rgba(64, 224, 208, 0.467);
  z-index: 2;
}

.main {
  width: 100%;
}

.footer {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
}

.projects {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 36px;
}

@media (min-width: 1400px) and (max-width: 1920px) {
  .App {
    --width: calc( ( (100vw - 1400px) / (1920 - 1400) ) * (400 - 300) + 300px);
  }
}

@media (min-width: 1023px) and (max-width: 1400px) {
  .App {
    --width: calc( ( (100vw - 300px) / (1400 - 300) ) * (580 - 120) + 120px);
  }
}

@media (max-width: 1023px) {
  .App {
    --width: calc( ( (100vw - 360px) / (1023 - 360) ) * (900 - 280) + 280px);
  }
  .projects {
    flex-direction: column;
    align-items: center;
  }
}

