.cards {
  margin: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  background-color: var(--color-second);
}

.cardsTitle {
  position: sticky;
  top: 100px;
  margin: 0;
  line-height: 1.8;
  background-color: var(--color-second);
  color: var(--color-text);
}

