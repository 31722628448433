.card {
  margin: 10px 0 0;
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 6px;
  border: 2px solid gray;
  border-radius: 8px;
  background-color: var(--color-fifth);
}

.cardTitle {
  align-self: center;
  color: var(--color-main);
}

.cardInfoContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

@media (max-width: 1400px) {
  .cardInfoContainer {
    flex-direction: column-reverse;
  }
}

.previewImage {
  margin-top: 20px;
  width: var(--width);
  height: calc(var(--width) * 187/372);
  border-radius: 8px;
}

@media (max-width: 1400px) {
  .previewImage {
    max-width: 372px;
    max-height: 187px;
  }
}

.cardParagraph {
  margin: 10px 0 0;
  width: var(--width);
  text-align: left;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}

.cardSpan {
  font-weight: 600;
}

.cardAboutContainer {
  height: 100%;
  text-align: center;
}

.cardLinkContainer {
  margin: 4px 0;
  text-align: left;
  text-decoration-line: none;
  color: var(--color-sixth);
}

@media (max-width: 1023px) {
  .cardLinkContainer {
    width: var(--width);
  }
}

.cardLink {
  text-decoration-line: none;
  color: var(--color-link);
}

